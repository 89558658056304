import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dialog } from '@capacitor/dialog';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import {
  addHours, addMinutes, differenceInBusinessDays, differenceInCalendarDays, eachWeekendOfMonth, endOfDay, getDaysInMonth, isAfter, startOfDay, startOfToday,
} from 'date-fns';
import { addMonths } from 'date-fns/addMonths';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { CalendarItem } from 'src/app/components/calendar/calendar.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SegmentListComponent } from 'src/app/components/segment-list/segment-list.component';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { SelectSiteButtonComponent } from 'src/app/components/select-site/select-site.component';
import { SelectTicketsDialog } from 'src/app/components/select-tickets/dialog/select-tickets.dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.dialog';
import { workdayCost, workdayHours } from 'src/app/constants/work';
import { LocationService } from 'src/app/location/location.service';
import { Schedule } from 'src/app/models/schedule.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { WorkMachine } from 'src/app/models/work-machine.model';
import { getAbsences } from 'src/app/report/helper';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { AddScheduleItem, SchedulesService } from 'src/app/services/schedules.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';
import { WorkMachinesService } from 'src/app/services/work-machines.service';
import { getDateFromTimestamp } from 'src/app/utility/item';
import { getUniqueGuid } from 'src/app/utility/list';
import { getDateWithTimezone, getNewRepeatDate } from 'src/app/utility/time';

@Component({
  selector: 'app-add-schedule-dialog',
  templateUrl: './add-schedule.dialog.html',
  styleUrls: ['./add-schedule.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
    SelectDayComponent,
    SelectSiteButtonComponent,
    SegmentListComponent,
  ],
})
export class AddScheduleDialogComponent {
  private translate = inject(TranslateService);
  private schedulesService = inject(SchedulesService);
  private sitesService = inject(SitesService);

  private machinesInUse$: Observable<string[]>;
  private day: number;
  private cost: { period: 'hour' | 'day' | 'week' | 'month'; amount: number };
  private absences = getAbsences(this.translate);

  formGroup: FormGroup;

  role$ = this.usersService.role$.pipe(
    shareReplay(1),
  );

  scheduleSite = signal<Site>(null);
  isWeekend = signal<boolean>(false);
  isEndCleaning = signal(false);
  actualHours$: Observable<number>;
  name = signal('');
  selectedMachine = signal<WorkMachine>(null);

  sites$ = this.sitesService.getActiveList().pipe(
    filter((sites) => sites?.length > 0),
    tap((sites) => {
      if (sites.length === 1) {
        const selectedSite = sites[0];
        if (selectedSite) {
          this.scheduleSite.set(selectedSite);
        }
      }
    }),
    map((sites) => sites.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  machines$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.workMachinesService.getList({ contractor: contractor.guid })),
    switchMap((machines) => combineLatest([of(machines), this.machinesInUse$])),
    map(([machines, inUse]) => machines.filter((m) => !inUse.includes(m.guid))),
    shareReplay(1),
  );

  projects$ = combineLatest([this.contractorsService.getCurrentContractor(), toObservable(this.scheduleSite)]).pipe(
    filter(([contractor, site]) => contractor != null && site != null),
    switchMap(([contractor, site]) => this.siteProjectsService.getList({ contractor: contractor.guid, site: site.guid })),
    map((projects) => projects?.sort(fieldSorter(['name'])) ?? []),
    tap((projects) => this.formGroup.get('project').setValue(projects[0])),
    shareReplay(1),
  );

  workday = signal(workdayHours);
  workdayCost = signal(workdayCost);
  dayCost = signal<string>(null);
  distance = signal(0);
  isUser = signal(true);
  includeWeekend = signal(false);
  selectedTickets = signal<RoomTicket[]>([]);
  viewSegment = signal<string>('allocate');
  dateSegment = signal<string>('normal');

  readonly viewSegmentButtons = [
    {
      value: 'allocate',
      name: 'schedule.schedule',
      show: true,
    },
    {
      value: 'absence',
      name: 'timesheet.absence',
      show: true,
    },
  ];

  readonly dateSegmentButtons = [
    {
      value: 'normal',
      name: 'calendar.site.schedule',
      show: true,
    },
    {
      value: 'repeat',
      name: 'calendar.site.repeat',
      show: true,
    },
  ];

  updating = signal(false);
  readonly minDate = getDateWithTimezone(startOfToday());
  selectedRepeatOption = signal<SubItem & { start: string; end: string }>(null);

  repeatOptions = signal([
    {
      guid: 'daily',
      name: this.translate.instant('calendar.site.repeatOptions.daily'),
    },
    {
      guid: 'weekly',
      name: this.translate.instant('calendar.site.repeatOptions.weekly'),
    },
    {
      guid: 'every2Week',
      name: this.translate.instant('calendar.site.repeatOptions.every2Week'),
    },
    {
      guid: 'monthly',
      name: this.translate.instant('calendar.site.repeatOptions.monthly'),
    },
    {
      guid: 'yearly',
      name: this.translate.instant('calendar.site.repeatOptions.yearly'),
    },
  ]);

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private siteProjectsService: SiteProjectsService,
    private usersService: UsersService,
    private workMachinesService: WorkMachinesService,
    private navParams: NavParams,
  ) {
    this.isUser.set(!this.navParams.get('isMachine'));
    const site = this.navParams.get('site');
    this.scheduleSite.set(site);
    const item = this.navParams.get('user');
    const date: Date = this.navParams.get('date');
    this.day = date?.getDay() ?? this.navParams.get('day');
    this.machinesInUse$ = this.navParams.get('machines');
    const useDateTimes = this.navParams.get<boolean>('useDateTimes');
    const calendarItem = this.navParams.get<CalendarItem<Schedule>>('calendarItem');

    const start = useDateTimes ? date : addHours(startOfDay(date), 7);
    const end = useDateTimes ? addHours(date, 1) : addMinutes(addHours(start, 8), 30);
    this.updating.set(calendarItem != null);
    const repeatGuid = this.updating() ? calendarItem.item.times.find((it) => it.user === item.guid)?.repeatGuid : null;

    this.formGroup = this.formBuilder.group({
      days: [1, [Validators.required, Validators.min(1)]],
      startDate: [calendarItem ? getDateWithTimezone(calendarItem.startDate) : getDateWithTimezone(startOfDay(date))],
      endDate: [calendarItem ? getDateWithTimezone(calendarItem.endDate) : getDateWithTimezone(endOfDay(date))],
      text: [calendarItem?.information ?? ''],
      machine: this.isUser() ? [] : [{ guid: item.guid }],
      project: [calendarItem?.item.projects?.find((it) => it.user === item.guid)?.project ?? null, this.isUser() ? Validators.required : Validators.nullValidator],
      start: [calendarItem ? getDateWithTimezone(calendarItem.startDate) : getDateWithTimezone(start)],
      end: [calendarItem ? getDateWithTimezone(calendarItem.endDate) : getDateWithTimezone(end)],
      absence: [calendarItem?.item.times.find((it) => it.user === item.guid)?.absence ?? null],
      repeatGuid: [repeatGuid],
    });

    if (site?.location && item.location) {
      const distance = this.locationService.calculateDistance(item.location, site.location);
      this.distance.set(distance);
    }

    if (item.hourlyRate) {
      this.cost = { period: 'hour', amount: item.hourlyRate };
      this.calculateCost(1);
    } else if (item.cost) {
      this.cost = { period: item.period, amount: item.cost };
      this.calculateCost(1);
    }
    this.name.set(item.displayName ?? item.name);

    this.isWeekend.set(this.day === 0 || this.day === 6);
  }

  private calculateCost(days: number): string {
    let ret = 0;
    if (this.scheduleSite()?.noPaySite) {
      return '0.00';
    }
    if (this.cost?.period === 'hour') {
      if (this.day === 0) {
        ret = days * 8 * 2 * this.cost.amount * 1.35;
      } else if (this.day === 6) {
        ret = days * 4 * this.cost.amount * 1.35 + (4 * this.cost.amount * 2);
      } else {
        ret = days * 8 * this.cost.amount * 1.35;
      }
    } else if (this.cost?.period === 'day') {
      ret = days * this.cost.amount;
    } else if (this.cost?.period === 'week') {
      ret = days * (this.cost.amount / 5);
    } else if (this.cost?.period === 'month') {
      const weekends = eachWeekendOfMonth(startOfToday()).length;
      ret = days * (this.cost.amount / (getDaysInMonth(startOfToday()) - weekends));
    }
    this.dayCost.set(ret.toFixed(2));
  }

  private setDays(includeWeekend: boolean) {
    const startDate = startOfDay(new Date(this.formGroup.get('startDate').value));
    let endDate = startOfDay(new Date(this.formGroup.get('endDate').value));
    if (isAfter(startDate, endDate)) {
      this.formGroup.get('endDate').setValue(getDateWithTimezone(startDate));
      endDate = startOfDay(new Date(this.formGroup.get('endDate').value));
    }
    let days = 1;
    if (includeWeekend) {
      days = differenceInCalendarDays(endDate, startDate) + 1;
    } else {
      days = differenceInBusinessDays(endDate, startDate) + 1;
    }
    this.calculateCost(days);
    this.formGroup.get('days').setValue(days);
  }

  private async remove(schedule: Schedule) {
    const user = this.navParams.get('user');
    schedule = this.schedulesService.removeUser(schedule, user.guid);
    return this.schedulesService.update(schedule);
  }

  onIncludeWeekend(event: IonToggleCustomEvent<ToggleChangeEventDetail<unknown>>) {
    this.includeWeekend.set(event.detail.checked);
    this.setDays(this.includeWeekend());
  }

  onDateChange(value: string, field: string) {
    this.formGroup.get(field).setValue(value);
    this.setDays(this.includeWeekend());
  }

  selectMacines(items: WorkMachine[]) {
    this.modalCtrl.create({ component: SelectDialogComponent<WorkMachine>, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('machine').setValue(null);
            this.selectedMachine.set(null);
          } else {
            this.formGroup.get('machine').setValue({ guid: data.data.guid });
            this.selectedMachine.set(data.data);
          }
        }
      });
    });
  }

  selectTickets() {
    this.modalCtrl.create({ component: SelectTicketsDialog, componentProps: { site: this.scheduleSite() } }).then((m) => {
      m.present();

      m.onDidDismiss<{ tickets: RoomTicket[] }>().then((data) => {
        if (data.data) {
          this.selectedTickets.set(data.data.tickets);
        }
      });
    });
  }

  onAbsence() {
    const items = this.absences;
    const clearButton = this.formGroup.get('absence').value != null;
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('absence').setValue(null);
          } else {
            this.formGroup.get('absence').setValue(data.data.key);
          }
        }
      });
    });
  }

  selectRepeatOption() {
    const clearButton = this.selectedRepeatOption() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SubItem>, componentProps: { items: this.repeatOptions(), clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.selectedRepeatOption.set(null);
          } else {
            data.data.start = this.formGroup.get('startDate').value;
            this.selectedRepeatOption.set(data.data);
          }
        }
      });
    });
  }

  onRepeatStartChange(value: string) {
    this.selectedRepeatOption.update((val) => {
      val.start = value;
      return val;
    });
  }

  onRepeatEndChange(value: string) {
    this.selectedRepeatOption.update((val) => {
      val.end = value;
      return val;
    });
  }

  onSave() {
    const value = this.formGroup.value;
    const deadlines: Date[] = [];
    if (this.selectedRepeatOption() != null) {
      const id = this.selectedRepeatOption().guid;
      const valueStart = new Date(value.start);
      const startRepeat = new Date(this.selectedRepeatOption().start);
      const endRepeat = this.selectedRepeatOption().end ? new Date(this.selectedRepeatOption().end) : addMonths(startRepeat, 12);
      let current = startRepeat;
      current.setHours(valueStart.getHours());
      while (current <= endRepeat) {
        deadlines.push(current);
        current = getNewRepeatDate(id, current);
      }
    }

    const days = value.days;
    const text = value.text;
    const machine = value.machine?.guid ?? null;
    const project = this.viewSegment() === 'allocate' ? value.project?.guid ?? null : null;
    const contractor = this.contractorsService.contractorS().guid;
    const startDate = new Date(this.formGroup.get('startDate').value);
    const includeWeekend = this.includeWeekend();
    const roomTickets = this.selectedTickets();
    const site = this.viewSegment() === 'allocate' ? this.scheduleSite() : null;
    const absence = this.viewSegment() === 'absence' ? value.absence : null;
    const start = deadlines?.length > 0 ? deadlines[0] : new Date(value.start);
    const repeatGuid = deadlines.length > 0 ? getUniqueGuid(new Date().getTime()) : null;

    this.dismiss({
      days, text, machine, project, start, end: new Date(value.end),
      contractor, startDate, includeWeekend, roomTickets, site, absence, status: 'published',
      deadlines, repeatGuid,
    });
  }

  onDelete() {
    const repeatGuid = this.formGroup.get('repeatGuid').value;
    if (repeatGuid) {
      this.modalCtrl.create({
        component: ConfirmDialogComponent, componentProps: {
          title: this.translate.instant('general.delete'),
          text: this.translate.instant('calendar.removeThisOrAllSchedules'),
          buttons: [this.translate.instant('general.cancel'), this.translate.instant('calendar.onlyThis'), this.translate.instant('tickets.all')],
        },
        cssClass: ['small-modal', 'stack-modal'],
      }).then((m) => {
        m.present();
        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data === 0) {
              return;
            } else if (data.data === 1) {
              // This
              const calendarItem = this.navParams.get<CalendarItem<Schedule>>('calendarItem');
              this.remove(calendarItem.item).then(async () => this._modal.dismiss({ deleted: true }));
            } else if (data.data === 2) {
              // Remove all
              const calendarItem = this.navParams.get<CalendarItem<Schedule>>('calendarItem');
              const user = this.navParams.get('user');
              this.schedulesService.getListOnce({ site: calendarItem.item.site, user: user.guid }).pipe(
                take(1),
              ).subscribe(async (schedules) => {
                const dateFrom = getDateFromTimestamp(calendarItem.item.dateObj);
                const repeats = schedules
                  .filter((it) => it.times.some((itt) => itt.repeatGuid === repeatGuid))
                  .filter((it) => isAfter(getDateFromTimestamp(it.dateObj), addMinutes(dateFrom, -1)));
                for (const schedule of repeats) {
                  await this.remove(schedule);
                }
                this._modal.dismiss({ deleted: true });
              });
            }
          }
        });
      });
    } else {
      Dialog.confirm({
        message: `${this.translate.instant('general.delete') as string}?`,
        okButtonTitle: this.translate.instant('general.yes'),
        cancelButtonTitle: this.translate.instant('general.no'),
      }).then((dialog) => {
        if (dialog.value) {
          // Delete
          const calendarItem = this.navParams.get<CalendarItem<Schedule>>('calendarItem');
          this.remove(calendarItem.item).then(async () => this._modal.dismiss({ deleted: true }));
        }
      });
    }
  }

  async dismiss(item?: AddScheduleItem) {
    return this._modal.dismiss(item);
  }
}
